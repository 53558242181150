import React from "react";
import Layout from "../components/layout";
import Nav from "../components/nav";
import SEO from "../components/seo";
import UpcomingEvents from "../components/events/upcoming-events";
import PassedEvents from "../components/events/passed-events";
import './booking-and-prices.css';
import { useStaticQuery, graphql } from "gatsby"


const lang = {
  en: {
    header: 'Booking and prices',
    content1: '900 SEK for one day with two dives',
    content2: '1295 SEK for one day, two dives in Dalarö divepark incl. guide and air.',
    info: 'All bookings are made via the contact form.',
    upcomingEvents: 'Upcoming events',
    passedEvents: 'Passed events',
  },
  sv: {
    header: 'Bokning och priser',
    content1: '900 SEK en dag, två dyk',
    content2: '1295 SEK en dag, två dyk i Dalarö Divepark. Guide och luft ingår.',
    info: 'Bokning sker tillsvidare via kontaktformuläret.',
    upcomingEvents: 'Kommande aktiviteter',
    passedEvents: 'Tidigare aktiviteter',
  }
}

const BookingAndPrices = (props) => {
  const data = useStaticQuery(graphql`
  query UpcomingEventsQuery($locale: String) {
    allContentfulEvent(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          id
          eventName
          shortDescription
          date
          featuredImage {
            fluid(maxWidth: 180, quality: 85) {
              src
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }`)
  const currentDate = Date.parse(new Date());
  // const passedDate = Date.parse(edge.node.date) < currentDate;
  const passedEvents = data.allContentfulEvent.edges.filter(edge => {
    return Date.parse(edge.node.date) < currentDate
  })
  const upcomingEvents = data.allContentfulEvent.edges.filter(edge => {
    return Date.parse(edge.node.date) > currentDate
  })
  console.log("BookingAndPrices -> upComingEvents", upcomingEvents)
  console.log("BookingAndPrices -> passedEvents", passedEvents)

return (
  <Layout>
    <SEO title="Booking And Prices" description="Book your unique scuba dive" />
    <Nav />
    <div className="booking-and-prices__header"></div>
    <div className="booking-and-prices__section">
      <div className="booking-and-prices__box">
        <h1>{lang.en.header}</h1>
        <div className="booking-and-prices__content">
          <p>{lang.en.content1}</p>
          <p>{lang.en.content2}</p>
          <p>{lang.en.info}</p>
        </div>
      </div>
      {/* // TODO: WIP */}
      {/* <div className="events">
        <h3 className="events__header">{lang.en.upcomingEvents}</h3>
          <UpcomingEvents upcomingEvents={upcomingEvents}/>
        <h3 className="events__header">{lang.en.passedEvents}</h3>
          <PassedEvents passedEvents={passedEvents}/>
      </div> */}
    </div>
  </Layout>
)
}

export default BookingAndPrices;
